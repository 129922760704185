export const transferData = [
  {
    id: 1,
    email: 'openingseeker@gmail.com',
    pgyLevel: 'PGY-1',
    currentProgram: 'Anesthesia',
    desiredPrograms: ['Anesthesia'],
    programLocation: 'Poughkeepsie, New York',
  },
  {
    id: 3,
    email: 'sgpierre001@gmail.com',
    pgyLevel: 'PGY-0',
    currentProgram: 'Emergency Medicine',
    desiredPrograms: ['Anesthesia'],
    programLocation: 'NY, New York',
  },
  {
    id: 4,
    email: 'warsemaf@gmail.com',
    pgyLevel: 'PGY-1',
    currentProgram: 'Family Medicine',
    desiredPrograms: ['Family Medicine'],
    programLocation: 'Detroit, Michigan',
  },
  {
    id: 5,
    email: 'jose.hidalpa.2023@gmail.com',
    pgyLevel: 'PGY-1',
    currentProgram: 'Internal Medicine',
    desiredPrograms: ['Neurology', 'Physical Medicine & Rehabilitation'],
    programLocation: 'NY, New York',
  },
  {
    id: 7,
    email: 'jjcnbusiness@gmail.com',
    pgyLevel: 'PGY-1',
    currentProgram: 'Internal Medicine',
    desiredPrograms: ['Obstetrics & Gynecology'],
    programLocation: 'Stockton, California',
  },
  {
    id: 8,
    email: 'momogadha4@gmail.com',
    pgyLevel: 'PGY-0',
    currentProgram: 'Internal Medicine',
    desiredPrograms: ['Internal Medicine'],
    programLocation: 'Monroe, Louisiana',
  },
  {
    id: 9,
    email: 'drratanpalyadav@gmail.com',
    pgyLevel: 'PGY-1',
    currentProgram: 'Preliminary Medicine',
    desiredPrograms: ['Diagnostic Radiology'],
    programLocation: 'Johnstown, Pennsylvania',
  },
  {
    id: 10,
    email: 'ashvin.v2@yahoo.com',
    pgyLevel: 'PGY-1',
    currentProgram: 'Psychiatry',
    desiredPrograms: ['Psychiatry'],
    programLocation: 'Las Vegas, Nevada',
  },
]