import React from 'react'
import { sanitize } from 'dompurify'
import {
  Grid,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ListDataItem from 'components/Listing/ListDataItem'
import PhysicianCard from './PhysicianCard'
import { getPhysicianListItems, getSpecialty } from './helpers'
import { formatName } from 'common/helpers'

const Section = ({ title, fields }) => {
  const visibleFields = fields.filter(field => field.show)

  // Return nothing if there are no fields to show
  if (visibleFields.length === 0) return null

  return (
    <Accordion
      defaultExpanded
      sx={{
        width: '100%',
        mb: 2,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: 'background.paper',
        '&:before': { display: 'none' }, // Remove default accordion border
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h6' sx={{ color: 'primary.main' }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          {visibleFields.map((field, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <ListDataItem icon={field.icon} boolValue={field.value} text={field.text} />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

const PhysicianContent = ({ item = {}, signedIn }) => {
  const fields = getPhysicianListItems(item) || []
  const name = `${formatName(item.user.firstName)} ${formatName(item.user.lastName)}`
  const subspecialty = item.subspecialties?.[0]?.name || null
  const specialty = item.specialties?.[0] && getSpecialty(item.specialties[0].name, subspecialty)

  // Group fields by section
  const professionalDetails = fields.filter(field => field.section === 'Professional Details')
  const preferences = fields.filter(field => field.section === 'Preferences')
  const backgroundInformation = fields.filter(field => field.section === 'Background Information')

  return (
    <Grid container sx={{ padding: '20px 20px', height: 'auto' }}>
      <Grid container spacing={1} alignItems='center' item xs={12}>
        <Grid item xs={9}>
          <Typography variant='h4'>
            <b>{name}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Divider sx={{ height: '1px' }} />
      </Grid>
      <Grid item xs={12}>
        <PhysicianCard
          physician={item.user}
          specialty={specialty}
          physicianResume={item.resume}
          signedIn={signedIn}
        />
      </Grid>

      {/* Collapsible Sections */}
      <Section title='💼 Professional Details' fields={professionalDetails} />
      <Section title='📄 Background Information' fields={backgroundInformation} />
      <Section title='❤️ Preferences' fields={preferences} />

      {/* About and Job sections */}
      {item.about && (
        <Box sx={{ width: '100%', my: 2 }}>
          <Divider />
          <Typography variant='h5'>About {item.user.firstName}</Typography>
          <Typography variant='body1' dangerouslySetInnerHTML={{ __html: sanitize(item.about) }} />
        </Box>
      )}

      {item.aboutJob && (
        <Box sx={{ width: '100%', my: 2 }}>
          <Divider />
          <Typography variant='h5'>Ideal Job for {item.user.firstName}</Typography>
          <Typography variant='body1'>{item.aboutJob}</Typography>
        </Box>
      )}
    </Grid>
  )
}

export default PhysicianContent
