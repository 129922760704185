import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Paper,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  useTheme,
  IconButton,
  Typography,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ListDataItem from './ListDataItem'
import { getLocation, getSpecialty } from 'pages/Jobs/helpers'
import PhysicianSignUpDialog from '../Dialog/PhysicianSignUpDialog'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { format } from 'date-fns'

export const ListItemJob = ({ data, isActive, onClick, onFavoriteClick }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const location = getLocation(data.city, data.state)
  const specialty = getSpecialty(data.specialties, data.subspecialties)
  const { employerUserId, signedIn } = useSelector(state => state.identity)
  const [openDialog, setOpenDialog] = React.useState(false)

  const onDialogClose = () => {
    setOpenDialog(false)
  }
  const handleDialogOpen = () => {
    setOpenDialog(true)
    dispatch(
      fetchTrackEvent({
        eventName: 'favorite_lead_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        eventData: { jobId: data.id },
      }),
    )
  }

  const formattedDate = data.createdAt ? format(new Date(data.createdAt), 'MMMM dd, yyyy') : ''

  return (
    <Paper
      elevation={5}
      sx={{ cursor: 'pointer', position: 'relative' }}
      onClick={() => onClick(data.id)}
    >
      <Card
        sx={{
          minWidth: 275,
          ...(isActive && {
            backgroundColor: theme.palette.secondary.light,
          }),
        }}
      >
        <Grid container direction={'row'} spacing={1} wrap={'nowrap'}>
          <Grid item xs={10}>
            <CardHeader
              style={{ overflowWrap: 'break-word' }}
              title={data.title}
              titleTypographyProps={{ variant: 'h6' }}
              subheader={
                <>
                  {data.employerName}
                  {formattedDate && (
                    <Typography
                      variant='caption'
                      color='textSecondary'
                      sx={{ mt: 0.5, display: 'block', fontSize: '0.8rem' }}
                    >
                      Posted on: {formattedDate}
                    </Typography>
                  )}
                </>
              }
              sx={{
                display: 'flex',
                overflow: 'hidden',
                '& .MuiCardHeader-content': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {!data.deletedAt && !employerUserId && (
              <div onClick={e => e.stopPropagation()}>
                <IconButton
                  aria-label='favorite'
                  onClick={e => {
                    e.stopPropagation()
                    signedIn
                      ? onFavoriteClick({
                          jobId: data.id,
                          favorite: data.candidateJob ? !data.candidateJob.favorite : true,
                        })
                      : handleDialogOpen()
                  }}
                >
                  {data.candidateJob && data.candidateJob.favorite ? (
                    <FavoriteIcon color='error' />
                  ) : (
                    <FavoriteBorderIcon color='error' />
                  )}
                </IconButton>
                <PhysicianSignUpDialog
                  openDialog={openDialog}
                  handleDialogClose={onDialogClose}
                  feature='favorite'
                />
              </div>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ height: '1px' }} />
        <CardContent>
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12} sm={12} md={6}>
              {location && <ListDataItem icon={<LocationOnIcon />} text={location} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {specialty && <ListDataItem icon={<LocalHospitalIcon />} text={specialty} />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {data.deletedAt && (
        <Typography
          variant='overline'
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: theme.palette.info.light,
            color: theme.palette.common.white,
            padding: '2px 4px',
            borderRadius: '1px',
          }}
        >
          Archived
        </Typography>
      )}
    </Paper>
  )
}
