import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { sanitize } from 'dompurify'
import { Grid, Typography, Divider, Alert, Box, Link, IconButton, Button } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ListDataItem from 'components/Listing/ListDataItem'
import { getImage, getJobListItems } from './helpers'
import RecruiterCard from './RecruiterCard'
import { convertNewlinesToBreaks } from './helpers'
import { useJobs } from './useJobs'
import ApplicationDrawer from './ApplicationDrawer'
import JobActionsButton from './JobActionsButton'
import JobDrawer from 'components/Drawers/JobDrawer/JobDrawer'
import { archiveJob } from 'data/jobs/actions'
import { DeleteDialogButton } from 'components/Dialog/DialogButton'
import { format } from 'date-fns' // Importing date formatting utility

// Date formatting function for the posted date
const formatPostedDate = date => format(new Date(date), 'MMMM dd, yyyy')

const generateJobPostingSchema = job => {
  let employmentType
  switch (job.practiceType) {
    case 'Telemedicine':
      employmentType = 'FULL_TIME'
      break
    case 'FullTime':
      employmentType = 'FULL_TIME'
      break
    case 'PRN':
      employmentType = 'PER_DIEM'
      break
    case 'Locum':
      employmentType = 'TEMPORARY'
      break
    default:
      employmentType = 'PART_TIME'
      break
  }
  return {
    '@context': 'http://schema.org',
    '@type': 'JobPosting',
    title: job.title,
    description: job.about || job.aboutRaw ? sanitize(job.about || job.aboutRaw) : job.link,
    datePosted: new Date(job.createdAt).toISOString(),
    employmentType: employmentType,
    hiringOrganization: {
      '@type': 'Organization',
      name: job.employerName,
      sameAs: job.link || 'https://www.mystethi.com',
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressLocality: job.city,
        addressRegion: job.state,
        addressCountry: 'US',
      },
    },
    baseSalary: {
      '@type': 'MonetaryAmount',
      currency: 'USD',
      value: {
        '@type': 'QuantitativeValue',
        value: job.salary,
      },
    },
    qualifications: job.specialty,
    validThrough: '2024-12-31',
  }
}

const JobContent = ({
  item = {},
  signedIn,
  employerUserId,
  onFavoriteClick,
  jobs,
  activeItem,
  mobile = false,
}) => {
  useEffect(() => {
    document.title = `MyStethi - ${item?.title ? `${item.title} - ` : ''}${item?.employerName || 'Jobs'}`
  }, [item.title, item.employerName])

  const fields = getJobListItems(item, signedIn) || []
  const displayableFields = fields.filter(field => field.show)
  const dispatch = useDispatch()
  const employerImage = getImage(item?.employer?.image)
  const candidateRating = item.candidateRating ? item.candidateRating : {}
  const deletedAt = 'Archived: ' + new Date(item.deletedAt).toDateString()
  const archive = item.deletedAt === null
  const about = item.about !== '' && item.about !== null ? item.about : item.aboutRaw
  const aboutContent = convertNewlinesToBreaks(about)
  const {
    drawerJobApplicationOpen,
    handleJobApplicationDrawerClose,
    setJobApplicationDrawerOpen,
    drawerJobOpen,
    setDrawerJobOpen,
  } = useJobs()
  const [isEdit, setEdit] = useState(null)

  const handleEdit = () => {
    setEdit(true)
    setDrawerJobOpen(true)
  }

  const handleAdd = () => {
    setEdit(false)
    setDrawerJobOpen(true)
  }
  return (
    <>
      <JobDrawer
        open={drawerJobOpen}
        onClose={() => setDrawerJobOpen(false)}
        jobToEdit={item}
        isEdit={isEdit}
        setOpenDrawer={setDrawerJobOpen}
      />
      {activeItem ? (
        <Box display={{ xs: 'block', sm: 'block', md: 'contents' }}>
          <script type='application/ld+json'>
            {JSON.stringify(generateJobPostingSchema(item))}
          </script>
          <Grid container sx={{ padding: '20px 20px', height: 'auto', marginBottom: '100px' }}>
            <Grid container item spacing={1} alignItems='center' direction='row' xs={12}>
              <Grid container item xs={9} alignItems='center'>
                <Typography variant='h4' sx={{ display: 'flex', alignItems: 'center' }}>
                  <Link
                    href={`/jobs/${item.id}`}
                    target='_blank'
                    sx={{
                      color: 'inherit',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <b>{item.title}</b>
                  </Link>
                  {signedIn && !employerUserId && (
                    <IconButton
                      aria-label='favorite'
                      onClick={() =>
                        onFavoriteClick({
                          jobId: item.id,
                          favorite: item.candidateJob ? !item.candidateJob.favorite : true,
                        })
                      }
                      sx={{ marginLeft: 2 }}
                    >
                      {item.candidateJob && item.candidateJob.favorite ? (
                        <FavoriteIcon color='error' />
                      ) : (
                        <FavoriteBorderIcon color='error' />
                      )}
                    </IconButton>
                  )}
                </Typography>
              </Grid>

              {/* Add new job button */}
              {signedIn && employerUserId && (
                <Grid container item xs={3} justifyContent='flex-end'>
                    <Button variant='contained' size='small' onClick={handleAdd}>
                      Add New Job
                    </Button>
                </Grid>
              )}
            </Grid>

            {/* Employer Name, Posted Date, Archived Alert, and Editable Job Buttons */}
            <Grid container item xs={12} direction='row' alignItems='flex-start' spacing={1}>
              {/* Employer Name */}
              <Grid item xs={12}>
                <Typography variant='h6'>
                  <b>{item.employerName}</b>
                </Typography>
              </Grid>

              {/* Posted Date */}
              {item.createdAt && (
                <Grid item xs={12}>
                  <Typography variant='caption' color='textSecondary'>
                    Posted on: {formatPostedDate(item.createdAt)}
                  </Typography>
                </Grid>
              )}

              {/* Archived Tag */}
              {item.deletedAt !== null && (
                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                  <Alert icon={false} severity='info'>
                    {deletedAt}
                  </Alert>
                </Grid>
              )}
              {signedIn && employerUserId && (
                <Grid container item xs={6} flexDirection='row' sx={{ marginTop: '2px' }}>
                  <Button variant='outlined' size='small' onClick={() => handleEdit()}>
                    Edit
                  </Button>
                  <Grid item sx={{ paddingLeft: '5px' }}>
                    <DeleteDialogButton
                      size='small'
                      isDeleted={item.deletedAt !== null}
                      onClick={() =>
                        dispatch(
                          archiveJob({
                            id: item.id,
                            archive: archive,
                            employerId: item.employer.id,
                          }),
                        )
                      }
                    >
                      {archive ? 'Archive Job' : 'Restore Job'}
                    </DeleteDialogButton>
                  </Grid>
                </Grid>
              )}
              {signedIn && !employerUserId && (
                <Grid container item xs={12} spacing={2} flexDirection='row'>
                  <JobActionsButton
                    jobId={item.id}
                    candidateRating={candidateRating}
                    candidateJobApplication={item.candidateJobApplication}
                    handleDrawerOpen={setJobApplicationDrawerOpen}
                    mobile={mobile}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sx={{ margin: '10px 0px' }}>
              <Divider sx={{ height: '1px', marginBottom: '10px' }} />
            </Grid>

            {/* Job Content */}
            <Grid container item xs={12} direction='row'>
              <Grid container item sm={12}>
                <RecruiterCard
                  recruiter={item.employerUser}
                  jobId={item.id}
                  signedIn={signedIn}
                  employerUserId={employerUserId}
                />
              </Grid>
              <Grid
                container
                item
                justifyContent='space-between'
                sm={12}
                md={12}
                rowSpacing={1}
                sx={{ marginBottom: '10px' }}
              >
                {displayableFields.map((field, index) => (
                  <Grid item xs={6} key={index}>
                    <ListDataItem
                      icon={field.icon}
                      boolValue={field.value}
                      text={field.text}
                      isLink={field.isLink}
                      link={field.link}
                    />
                  </Grid>
                ))}
              </Grid>
              {employerImage && (
                <Grid container item xs={12}>
                  <Box
                    component='img'
                    sx={{
                      height: '100px',
                      width: '100%',
                      overflow: 'hidden',
                      objectFit: 'cover',
                      margin: '20px 0px',
                    }}
                    alt={item.employerName}
                    src={employerImage}
                  />
                </Grid>
              )}
              {about && (
                <Grid
                  item
                  xs={12}
                  justifyContent='flex-start'
                  sx={{ marginTop: '10px', marginBottom: '50px' }}
                >
                  <Divider sx={{ height: '1px', margin: '20px 0px' }} />
                  <Typography
                    variant='body1'
                    dangerouslySetInnerHTML={{ __html: sanitize(aboutContent) }}
                  />
                </Grid>
              )}
              {!about && item.link && !employerUserId && (
                <Typography variant='body1' sx={{ marginTop: '10px' }}>
                  For more information about this job, please visit{' '}
                  <Link href={item.link} target='_blank'>
                    {item.employerName}'s website
                  </Link>
                </Typography>
              )}
              {item.employer.about && (
                <Grid
                  item
                  xs={12}
                  justifyContent='flex-start'
                  sx={{ marginTop: '10px', marginBottom: '50px' }}
                >
                  <Divider sx={{ height: '1px' }} />
                  <Typography variant='h4'>About {item.employerName}</Typography>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant='body1'
                        dangerouslySetInnerHTML={{ __html: sanitize(item.employer.about) }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {signedIn && !employerUserId && (
            <ApplicationDrawer
              open={drawerJobApplicationOpen}
              onClose={handleJobApplicationDrawerClose}
              jobs={jobs}
              job={item}
            />
          )}
        </Box>
      ) : employerUserId ? (
        <Box display={{ xs: 'none', sm: 'none', md: 'contents' }}>
          <Grid container item justifyContent='center' sx={{ padding: '20px 20px' }}>
            <Button variant='contained' size='small' onClick={handleAdd}>
              Add your first job
            </Button>
          </Grid>
        </Box>
      ) : (
        <Typography variant='body2' sx={{ padding: '50px' }}>
          No jobs found, please try a different filter criteria
        </Typography>
      )}
    </>
  )
}

export default JobContent
