import React from 'react'
import { Box, Typography, Card, CardContent, Chip, Button, useTheme } from '@mui/material'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'

const ResidencyTransferCard = ({ transfer }) => {
  const theme = useTheme()
  
  const handleContact = () => {
    window.location.href = `mailto:${transfer.email}?subject=Residency Transfer Inquiry`
  }
  
  return (
    <Card 
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          boxShadow: 3,
          transform: 'translateY(-2px)',
          transition: 'all 0.2s ease-in-out'
        }
      }}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PersonIcon fontSize="small" color="primary" sx={{ mr: 0.5 }} />
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {transfer.pgyLevel}
            </Typography>
          </Box>
          {transfer.programLocation && (
            <Typography 
              variant="body2" 
              color="textSecondary"
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                backgroundColor: theme.palette.grey[50],
                px: 1,
                py: 0.5,
                borderRadius: 1,
              }}
            >
              <LocationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
              {transfer.programLocation}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, flex: 1 }}>
          <Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Current Program
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {transfer.currentProgram ? (
                <Chip 
                  label={transfer.currentProgram}
                  size="small"
                  sx={{ 
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                  }}
                />
              ) : (
                <Chip 
                  label="Unmatched"
                  size="small"
                  sx={{ 
                    backgroundColor: theme.palette.grey[100],
                    color: theme.palette.text.secondary,
                  }}
                />
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SwapHorizIcon color="primary" />
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Desired Program
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {transfer.desiredPrograms?.map((program, index) => (
                <Chip 
                  key={index}
                  label={program}
                  size="small"
                  sx={{ 
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.main,
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={{ 
          mt: 2,
          pt: 2,
          borderTop: `1px solid ${theme.palette.grey[200]}`,
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Button 
            variant="outlined"
            size="small"
            startIcon={<EmailIcon />}
            onClick={handleContact}
            sx={{
              borderColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
                borderColor: theme.palette.primary.main,
              }
            }}
          >
            Contact
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ResidencyTransferCard 