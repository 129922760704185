import React from 'react'
import { Grid, Typography, Card, Button, Box } from '@mui/material'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import { Link } from 'react-router-dom'

const Careers = () => {
  const job = {
    title: 'Fractional Sales Leader',
    description:
      'We’re looking for a fractional Sales Leader to help shape and drive our sales strategy at MyStethi—a platform dedicated to eliminating the noise from the job search for medical professionals.\n\n' +
      'This is a part-time, fully remote role with hourly pay, but with the goal of bringing the right person onto our core team after a 1-2 month trial period. We need someone who is deeply familiar with healthcare recruiting and has a strong sales background.',
    linkedinUrl: 'https://www.linkedin.com/jobs/view/4162826940/',
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f9f9f9',
        py: 5,
        px: 3,
      }}
    >
      <Grid container justifyContent='center' spacing={4}>
        <Grid item xs={12} md={8} sx={{ textAlign: 'center' }}>
          <Typography variant='h4' fontWeight='bold'>
            Careers at MyStethi
          </Typography>
          <Typography variant='body1' color='text.secondary' sx={{ mt: 2, mx: 'auto' }}>
            MyStethi is a **bootstrapped, small startup** on a mission to **make job searches easier
            for doctors**. We’re a **fully remote, scrappy team** passionate about building
            technology that simplifies career transitions for healthcare professionals. If you
            thrive in fast-paced environments and want to **shape the future of medical hiring**,
            we’d love to have you on board!
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8} md={6}>
          <Card elevation={4} sx={{ p: 3, textAlign: 'center', backgroundColor: '#fff' }}>
            <WorkOutlineIcon color='primary' sx={{ fontSize: 50, mb: 1 }} /> {/* Job Icon */}
            <Typography variant='h5' fontWeight='bold'>
              {job.title}
            </Typography>
            <Typography
              variant='body1'
              color='text.secondary'
              sx={{ mt: 1, whiteSpace: 'pre-line' }}
            >
              {job.description}
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Link to={job.linkedinUrl} target='_blank' rel='noopener noreferrer'>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<LinkedInIcon />}
                  sx={{ fontSize: 16 }}
                >
                  View & Apply on LinkedIn
                </Button>
              </Link>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Careers
