import React from 'react'
import { Box, Typography, Card, CardContent, Button, useTheme } from '@mui/material'
import SurveyIcon from '@mui/icons-material/Assessment'

const InfoSection = () => {
  const theme = useTheme()

  return (
    <Card sx={{ mb: 4, backgroundColor: theme.palette.grey[50] }}>
      <CardContent sx={{ p: 3 }}>
        <Box>
          <Typography variant="h6" sx={{ color: theme.palette.primary.main, mb: 1 }}>
            Help Build the Residency Transfer Platform
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: theme.palette.text.secondary }}>
            We're creating a free platform to help residents find transfer opportunities and programs fill vacancies. 
            Your input will help us build features that matter most to the medical community.
          </Typography>
          <Button
            variant="contained"
            startIcon={<SurveyIcon />}
            href="https://forms.gle/LeAZ56xtLz9qMx4T7"
            target="_blank"
            sx={{ 
              background: theme.palette.primary.main,
              '&:hover': {
                background: theme.palette.primary.dark,
              }
            }}
          >
            Take 2-Minute Survey
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default InfoSection 