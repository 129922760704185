import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { useIdentity } from 'pages/Identity/Login'
import { Grid, Typography, FormControlLabel, Switch } from '@mui/material'
import { styled } from '@mui/material/styles'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { List } from 'components/Listing/List'
import Loader from 'components/Loader'
import { getCandidateJobs, getHospitalJobs } from 'data/jobs/actions'
import { fetchJobFilterValues } from 'src/redux/filters'
import JobContent from './JobContent'
import { Filters } from '../../components/Filters'
import { FilterType } from '../../components/Filters/filterDefinitions'
import { candidateTypeDefinitions } from 'components/Filters/candidateTypeDefinitions'
import { filterDefinitions } from 'components/Filters/filterDefinitions'
import { fetchUpdateCandidateJob } from 'src/redux/candidateJob'
import { applyFilters } from 'components/Filters/helpers'

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: theme.palette.grey[400],
    '&.Mui-checked': {
      color: theme.palette.error.main,
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.error.light,
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.grey[300],
  },
}))

const Jobs = () => {
  const dispatch = useDispatch()
  const signedIn = useSelector(state => state.identity.signedIn)
  const {
    candidateType,
    specialties = [],
    loading: candidateLoading,
  } = useSelector(state => state.candidate)
  const { loading: employerUserLoading } = useSelector(state => state.employerUser)
  const loading = candidateLoading || employerUserLoading
  const { employerId, employerUserId, candidateId, userId, isEmployerAdmin } = useIdentity()
  const [activeItem, setActiveItem] = useState()
  const [page, setPage] = useState(0)
  const pageSize = 50
  const { jobsList = [], isLoadingJobs } = useSelector(state => state.jobs)
  const { isLoadingFilters } = useSelector(state => state.filters).loading
  const filterValues = useSelector(state => state.filters.jobFilterValues) || {}
  const totalJobs = useSelector(state => state.jobs.totalJobs)
  const totalPages = Math.ceil(totalJobs / pageSize)
  const dataCall = employerUserId ? getHospitalJobs : getCandidateJobs
  const [searchParams, setSearchParams] = useSearchParams()
  const candidateTypeId = searchParams.get('candidateTypeId')
  const [displayedData, setDisplayedData] = useState(jobsList)
  const [isApplyingFilters, setIsApplyingFilters] = useState(false)

  // Added state for the "Favorites Only" filter
  const [favoritesOnly, setFavoritesOnly] = useState(false)

  const handleListItemClick = currId => {
    const activeJob = !isNil(currId)
      ? displayedData.find(j => `${j.id}` === `${currId}`)
      : displayedData.length > 0
        ? displayedData[0]
        : null
    setActiveItem(activeJob)
  }

  useEffect(() => {
    if (!signedIn) {
      dispatch(dataCall({ page, pageSize }))
    }
  }, [signedIn, dispatch, dataCall, page, pageSize])

  useEffect(() => {
    setActiveItem(jobsList[0])
  }, [jobsList])

  useEffect(() => {
    if (candidateType && candidateType.id && searchParams.size === 0) {
      const newParams = new URLSearchParams(searchParams)
      newParams.set('candidateTypeId', candidateType.id)
      const candidateTypeDefinition = candidateTypeDefinitions.find(
        def => def.id === candidateType.id,
      )
      const specialtyFilterDefinition = filterDefinitions.find(
        filter => filter.name === 'specialty',
      )
      if (candidateTypeDefinition?.useCandidateSpecialty === true) {
        if (specialties.length > 0) {
          newParams.set(specialtyFilterDefinition.queryStringKey, specialties[0].id)
        } else {
          newParams.delete(specialtyFilterDefinition.queryStringKey)
        }
      } else {
        newParams.delete(specialtyFilterDefinition.queryStringKey)
      }
      setSearchParams(newParams)
    }
  }, [candidateType, specialties])

  useEffect(() => {
    if (candidateTypeId) {
      const specialtyIdsParam = searchParams.get('specialtyIds')
      dispatch(
        fetchJobFilterValues({
          employerId: employerId,
          candidateTypeId: searchParams.get('candidateTypeId'),
          specialtyIds: specialtyIdsParam ? specialtyIdsParam.split('|') : [],
        }),
      )
    }
  }, [candidateTypeId])

  // Update displayedData when jobsList changes
  useEffect(() => {
    setDisplayedData(jobsList)
  }, [jobsList])

  // Handle URL parameter changes
  useEffect(() => {
    if (searchParams.size > 0) {
      setIsApplyingFilters(true)
      applyFilters({
        candidateId,
        employerUserId,
        searchParams,
        dispatch,
        getData: dataCall,
        page,
        pageSize,
        employerId,
        isEmployerAdmin,
        userId,
      })
      // Set a timeout to turn off the loading state after a short delay
      const timer = setTimeout(() => {
        setIsApplyingFilters(false)
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [searchParams])

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams)
    if (favoritesOnly) {
      updatedSearchParams.set('favorite', 'true')
    } else {
      updatedSearchParams.delete('favorite')
    }

    setIsApplyingFilters(true)
    applyFilters({
      candidateId,
      employerUserId,
      searchParams: updatedSearchParams,
      dispatch,
      getData: dataCall,
      page,
      pageSize,
      employerId,
      isEmployerAdmin,
      userId,
    })
    // Set a timeout to turn off the loading state after a short delay
    const timer = setTimeout(() => {
      setIsApplyingFilters(false)
    }, 500)
    return () => clearTimeout(timer)
  }, [favoritesOnly])

  const toggleFavoritesFilter = () => {
    setFavoritesOnly(!favoritesOnly)
  }

  const leftPanelHeader = 'Results: ' + `${totalJobs ? totalJobs.toLocaleString() : 0}` + ' Jobs'

  const applyJobFilters = ({ searchParams }) => {
    applyFilters({
      candidateId,
      employerUserId,
      searchParams,
      dispatch,
      getData: dataCall,
      page,
      pageSize,
      employerId,
      isEmployerAdmin,
      userId,
    })
  }

  const onFavoriteClick = ({ favorite, jobId }) => {
    const updatedData = displayedData.map(item =>
      item.id === jobId
        ? {
            ...item,
            candidateJob: {
              ...item.candidateJob,
              favorite,
            },
          }
        : item,
    )

    setDisplayedData(updatedData)

    if (activeItem && activeItem.id === jobId) {
      const updatedActiveItem = {
        ...activeItem,
        candidateJob: {
          ...activeItem.candidateJob,
          favorite,
        },
      }
      setActiveItem(updatedActiveItem)
    }

    dispatch(fetchUpdateCandidateJob({ candidateId, jobId, favorite }))
  }

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Filters
            filterType={FilterType.JOB}
            filterValues={filterValues}
            listType='job'
            loading={isLoadingFilters}
            applyFilters={applyJobFilters}
          />
        </Grid>
        {isLoadingJobs || loading || isApplyingFilters ? (
          <Loader />
        ) : (
          <Grid container sx={{ maxWidth: '70em' }}>
            <Grid
              container
              item
              sm={12}
              md={5}
              direction='column'
              alignContent='flex-start'
              sx={{ height: '100%' }}
            >
              <Grid
                container
                item
                alignItems='center'
                justifyContent='space-between'
                sx={{ width: '100%' }}
              >
                <Typography variant='h6' sx={{ padding: '10px 16px' }}>
                  {leftPanelHeader}
                </Typography>
                {signedIn && !employerUserId && (
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        checked={favoritesOnly}
                        onChange={toggleFavoritesFilter}
                        icon={<FavoriteBorderIcon />}
                        checkedIcon={<FavoriteIcon />}
                      />
                    }
                    label='Favorites Only'
                    labelPlacement='start'
                    sx={{ marginRight: '16px' }}
                  />
                )}
              </Grid>
              <Grid container alignItems='flex-start' sx={{ maxHeight: '100vh', overflow: 'auto' }}>
                <List
                  setPage={setPage}
                  page={page}
                  data={displayedData}
                  listType='job'
                  handleListItemClick={handleListItemClick}
                  dataTotal={totalJobs}
                  totalPages={totalPages}
                  signedIn={signedIn}
                  setDisplayedData={setDisplayedData}
                  onFavoriteClick={onFavoriteClick}
                  setActiveItem={setActiveItem}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              md={7}
              direction='column'
              sx={{ maxHeight: '100vh', overflow: 'auto' }}
            >
              <JobContent
                item={activeItem}
                signedIn={signedIn}
                employerUserId={employerUserId}
                onFavoriteClick={onFavoriteClick}
                jobs={jobsList}
                activeItem={activeItem}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Jobs
