import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ListDataItem from 'components/Listing/ListDataItem'
import { getProfileImage } from './helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import PhysicianSignUpDialog from '../../components/Dialog/PhysicianSignUpDialog'

const RecruiterCard = ({ recruiter, employerUserId, jobId }) => {
  const dispatch = useDispatch()
  const { email, signedIn } = useSelector(state => state.identity)
  const profileImage = getProfileImage(recruiter.profileImage)
  const subHeader = recruiter.jobTitle ? `${recruiter.jobTitle} @ ${recruiter.employer.name}` : null
  const [openDialog, setOpenDialog] = React.useState(false)
  const onDialogClose = () => {
    setOpenDialog(false)
  }

  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  const handleContactClick = () => {
    if (signedIn) {
      dispatch(
        fetchTrackEvent({
          eventName: 'recruiter_contact_click',
          eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
          userId: email,
          eventData: { recruiterUserId: recruiter.user.id, jobId: jobId },
        }),
      )
    } else {
      dispatch(
        fetchTrackEvent({
          eventName: 'recruiter_contact_lead_click',
          eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
          eventData: { recruiterUserId: recruiter.user.id, jobId: jobId },
        }),
      )
      handleDialogOpen()
    }
  }

  return (
    <Paper elevation={5} sx={{ width: '100%', marginBottom: '20px' }}>
      <Card sx={{ minWidth: 275, padding: '20px' }}>
        {/* Left-aligned header with consistent style */}
        <Typography variant='h6' component='div' sx={{ marginBottom: '10px', fontWeight: 'bold' }}>
          Recruiter Contact Card
        </Typography>
        <Grid container direction={'row'} spacing={1} wrap='nowrap'>
          <Grid item>
            <CardMedia
              sx={{ width: '100%', height: '70px' }}
              component='img'
              src={profileImage}
              image={profileImage}
              title='Listing Photo'
            />
          </Grid>
          <Grid container wrap='nowrap'>
            <CardHeader
              style={{ overflowWrap: 'break-word' }}
              titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }} // Ensure consistent recruiter name style
              title={`${recruiter.user.firstName} ${recruiter.user.lastName}`}
              subheader={subHeader}
            />
          </Grid>
        </Grid>

        <Grid container item justifyContent='flex-start' direction='row'>
          {signedIn && recruiter.user.email && recruiter.user.showEmail && (
            <Grid container item justifyContent='flex-start' xs={12}>
              <ListDataItem text={recruiter.user.email} icon={<EmailIcon />} />
            </Grid>
          )}
          {signedIn && recruiter.user.phone && recruiter.user.showPhone && (
            <Grid container item justifyContent='flex-start' xs={12}>
              <ListDataItem text={recruiter.user.phone} icon={<PhoneIphoneIcon />} />
            </Grid>
          )}
          {!employerUserId && (
            <CardActions>
              {signedIn ? (
                <a href={`mailto:${recruiter.user.email}`}>
                  <Button variant='contained' size='medium' onClick={handleContactClick}>
                    Contact
                  </Button>
                </a>
              ) : (
                <Button variant='contained' size='medium' onClick={handleContactClick}>
                  Contact
                </Button>
              )}
              <PhysicianSignUpDialog
                openDialog={openDialog}
                handleDialogClose={onDialogClose}
                feature='contact'
              />
            </CardActions>
          )}
        </Grid>
      </Card>
    </Paper>
  )
}

export default RecruiterCard
