import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { isNil, isEqual } from 'lodash' // Use isEqual to compare lists
import { Grid, Typography, Box } from '@mui/material'
import { List } from 'components/Listing/List'
import Loader from 'components/Loader'
import { Filters } from '../../components/Filters'
import { FilterType } from '../../components/Filters/filterDefinitions'
import { fetchAllCandidates } from 'src/redux/candidate'
import { applyFilters } from 'components/Filters/helpers'
import PhysicianContent from './PhysicianContent'
import { fetchProviderFilterValues } from 'src/redux/filters'

const Physicians = () => {
  const { signedIn, employerId, employerUserId } = useSelector(state => state.identity)
  const dispatch = useDispatch()
  const [activeItem, setActiveItem] = useState(null)
  const [page, setPage] = useState(0)
  const pageSize = 50
  const physicianList = useSelector(state => state.candidate.list) || []
  const { isLoadingFilters } = useSelector(state => state.filters).loading
  const filterValues = useSelector(state => state.filters.providerFilterValues) || {}
  const totalCandidates = useSelector(state => state.candidate.totalCandidates)
  const totalPages = Math.ceil(totalCandidates / pageSize)
  const { loading: isLoadingCandidates } = useSelector(state => state.candidate)
  const [searchParams] = useSearchParams()
  const [displayedData, setDisplayedData] = useState([]) // Start with an empty array
  const candidateTypeId = searchParams.get('candidateTypeId')

  const handleListItemClick = currId => {
    const activePhysician = !isNil(currId)
      ? displayedData.find(p => `${p.id}` === `${currId}`)
      : displayedData.length > 0
        ? displayedData[0]
        : null
    setActiveItem(activePhysician)
  }

  // Fetch provider filter values
  useEffect(() => {
    const specialtyIdsParam = searchParams.get('specialtyIds')
    const specialtyIds = specialtyIdsParam ? specialtyIdsParam.split('|') : []

    const filterValues = {}

    // Only add candidateTypeId if it exists
    if (candidateTypeId) {
      filterValues.candidateTypeId = candidateTypeId
    }

    // Only add specialtyIds if they exist
    if (specialtyIds.length > 0) {
      filterValues.specialtyIds = specialtyIds
    }
    dispatch(fetchProviderFilterValues(filterValues))
  }, [candidateTypeId, searchParams, dispatch])

  // Set displayedData and activeItem only when the physicianList changes, not on every render
  useEffect(() => {
    // Only update displayedData if physicianList has changed
    if (!isEqual(displayedData, physicianList)) {
      setDisplayedData(physicianList)
    }
    // Set the first physician as active if none selected
    if (!activeItem && physicianList.length > 0) {
      setActiveItem(physicianList[0])
    }
    if (physicianList.length === 0) {
      setActiveItem(null)
    }
  }, [physicianList, displayedData, activeItem])

  const leftPanelHeader =
    'Results: ' + `${totalCandidates ? totalCandidates.toLocaleString() : 0} ` + 'Providers'

  // Apply candidate filters
  const applyCandidateFilters = ({ searchParams }) => {
    applyFilters({
      searchParams,
      employerId,
      employerUserId,
      dispatch,
      getData: fetchAllCandidates,
      page,
      pageSize,
    })
  }

  // Trigger filter application when searchParams change
  useEffect(() => {
    applyCandidateFilters({ searchParams })
  }, [searchParams, page])

  return (
    <>
      <Grid container justifyContent='center'>
        {signedIn && Object.keys(filterValues).length > 0 && (
          <Grid item xs={12}>
            <Filters
              filterType={FilterType.CANDIDATE}
              filterValues={filterValues}
              listType={'provider'}
              loading={isLoadingFilters}
              applyFilters={applyCandidateFilters}
            />
          </Grid>
        )}
        {isLoadingCandidates ? (
          <Loader />
        ) : (
          <Grid container sx={{ maxWidth: '70em' }}>
            <Grid container item sm={12} md={5} direction='row' sx={{ height: '100%' }}>
              <Grid item>
                <Typography variant='h6' sx={{ padding: '10px 16px' }}>
                  {leftPanelHeader}
                </Typography>
              </Grid>
              <Grid container alignItems='flex-start' sx={{ maxHeight: '100vh', overflow: 'auto' }}>
                <List
                  setPage={setPage}
                  page={page}
                  data={displayedData}
                  listType={'provider'}
                  isPhysicianList={true}
                  handleListItemClick={handleListItemClick}
                  dataTotal={totalCandidates}
                  totalPages={totalPages}
                  signedIn={signedIn}
                  isLoading={isLoadingFilters}
                  setActiveItem={setActiveItem}
                  setDisplayedData={setDisplayedData}
                />
              </Grid>
            </Grid>
            <Box display={{ xs: 'none', sm: 'none', md: 'contents' }}>
              <Grid
                container
                item
                md={7}
                direction='column'
                sx={{ maxHeight: '100vh', overflow: 'auto' }}
              >
                {activeItem && <PhysicianContent item={activeItem} signedIn={signedIn} />}
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Physicians
