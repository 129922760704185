import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Typography, Button, useTheme, Link, Divider, TextField } from '@mui/material'
import SurveyIcon from '@mui/icons-material/Assessment'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { postNewsletterSubscription } from 'data/newsletter/actions'

const SalaryPage = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const handleEmailSubmit = e => {
    e.preventDefault()
    console.log('e is', e)
    dispatch(postNewsletterSubscription({ email: email, type: 'SALARY_SIGNUP' }))
    setSubmitted(true) // Show confirmation message
    setEmail('') // Clear the input field
  }

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{
        minHeight: '100vh',
        padding: '40px 20px',
        backgroundColor: theme.palette.primary.light,
        textAlign: 'center',
      }}
    >
      {/* Header */}
      <Typography variant='h3' sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
        Salary Transparency – Coming Soon!
      </Typography>

      {/* Description */}
      <Typography
        variant='body1'
        sx={{ marginTop: 2, maxWidth: '600px', color: theme.palette.secondary.dark }}
      >
        We're gathering real-world salary data to bring visibility and transparency to the medical
        community. Your contributions help create a fair and informed future for healthcare
        professionals. Don't worry, your responses will be completely anonymous.
      </Typography>

      {/* Survey Button */}
      <Button
        variant='contained'
        startIcon={<SurveyIcon />}
        sx={{
          marginTop: 4,
          padding: '12px 30px',
          fontSize: '1.1rem',
          background: 'linear-gradient(45deg, #0E7C7B 30%, #0E467C 90%)',
          color: '#FFF',
          borderRadius: '8px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            background: 'linear-gradient(45deg, #0B5F5E 30%, #092D55 90%)',
            transform: 'scale(1.05)',
          },
          transition: 'all 0.3s ease-in-out',
        }}
        href='https://docs.google.com/forms/d/e/1FAIpQLSf48Le6uS3sucGZaAXnx-FI89rOer2lK83LpgdDjFQT-KRx5A/viewform?usp=header'
        target='_blank'
      >
        Submit Your Salary
      </Button>

      {/* Email Notification Signup */}
      <Typography variant='body2' sx={{ marginTop: 4, color: theme.palette.secondary.dark }}>
        Want to be notified when this feature goes live?
      </Typography>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        component='form'
        onSubmit={handleEmailSubmit}
        sx={{ marginTop: 2 }}
      >
        <TextField
          variant='outlined'
          type='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='Enter your email'
          required
          sx={{
            backgroundColor: '#FFF',
            borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              height: '48px', // Match the button height
              borderRadius: '8px',
            },
          }}
        />
        <Button
          type='submit'
          variant='contained'
          sx={{
            height: '48px', // Match the text field height
            marginLeft: '8px', // Add space between the input and button
            padding: '0 20px',
            backgroundColor: theme.palette.primary.main,
            color: '#FFF',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              transform: 'scale(1.02)',
            },
            transition: 'all 0.2s ease-in-out',
          }}
        >
          Notify Me
        </Button>
      </Grid>
      {/* Success Message */}
      {submitted && (
        <Typography
          variant='body2'
          sx={{
            marginTop: 2,
            color: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CheckCircleIcon sx={{ marginRight: '5px', color: theme.palette.primary.main }} />
          Thanks for signing up! You'll be notified when this feature goes live.
        </Typography>
      )}

      {/* Divider */}
      <Divider
        sx={{ width: '60%', margin: '40px 0', backgroundColor: theme.palette.secondary.main }}
      />

      {/* Resource Links */}
      <Typography variant='h5' sx={{ marginBottom: 2, color: theme.palette.primary.dark }}>
        Explore Other Resources
      </Typography>

      <Grid container direction='column' spacing={2} sx={{ maxWidth: '400px' }}>
        <Grid item>
          <Link
            href='/jobs'
            underline='hover'
            sx={{
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.secondary.main,
              '&:hover': { color: theme.palette.primary.main },
            }}
          >
            Job Board <ArrowForwardIcon sx={{ marginLeft: '5px' }} />
          </Link>
        </Grid>
        <Grid item>
          <Link
            href='/directory'
            underline='hover'
            sx={{
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.secondary.main,
              '&:hover': { color: theme.palette.primary.main },
            }}
          >
            Employer Directory <ArrowForwardIcon sx={{ marginLeft: '5px' }} />
          </Link>
        </Grid>
        <Grid item>
          <Link
            href='/residency-transfer'
            underline='hover'
            sx={{
              fontSize: '1rem',
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.secondary.main,
              '&:hover': { color: theme.palette.primary.main },
            }}
          >
            Residency Transfer <ArrowForwardIcon sx={{ marginLeft: '5px' }} />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SalaryPage
