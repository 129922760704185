import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardHeader, CardMedia, CardActions, Button, Grid, Paper } from '@mui/material'
import { checkEmpty, hostAddress } from 'data/helpers'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ListDataItem from 'components/Listing/ListDataItem'
import { getPhysicianProfileImage } from './helpers'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'
import { formatName } from 'common/helpers'
const PhysicianCard = ({ physician, physicianResume, specialty }) => {
  const dispatch = useDispatch()
  const { email, employerId } = useSelector(state => state.identity)
  const profileImage = getPhysicianProfileImage(physician.profileImage)
  const name = formatName(physician.firstName) + ' ' + formatName(physician.lastName)
  const resume = checkEmpty(physicianResume) ? {} : physicianResume
  const { key, fileName } = resume
  const handleResumeClick = React.useCallback(() => {
    fetch(hostAddress(`/api/storage/resume?key=${key}&fileName=${fileName}`), {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json' }),
    }).then(response => response.blob().then(blob => window.open(URL.createObjectURL(blob))))
  }, [key, fileName])

  const handleContactClick = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'physician_contact_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        properties: { physicianUserId: physician.id },
        userId: email,
        employerId: employerId,
      }),
    )
  }

  return (
    <Paper elevation={5} sx={{ width: '100%', marginBottom: '20px' }}>
      <Card
        sx={{
          minWidth: 275,
          padding: '20px',
        }}
      >
        <Grid container direction={'row'} spacing={1} wrap='nowrap'>
          <Grid item>
            <CardMedia
              sx={{
                width: '100%',
                height: '100px',
                marginBottom: '20px',
              }}
              component='img'
              src={profileImage}
              image={profileImage}
              title='Physician Photo'
            />
          </Grid>
          <Grid container wrap='nowrap'>
            <CardHeader
              style={{ overflowWrap: 'break-word' }}
              title={name}
              subheader={
                <Grid container direction='column'>
                  <Grid item>{specialty}</Grid>
                  <Grid>
                    {resume.key && (
                      <a
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => handleResumeClick()}
                      >
                        View Resumé
                      </a>
                    )}
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
        {physician.email && physician.showEmail && (
          <Grid container item justifyContent='flex-start' xs={12}>
            <ListDataItem text={physician.email} icon={<EmailIcon />} />
          </Grid>
        )}
        {physician.phone && physician.showPhone && (
          <Grid container item justifyContent='flex-start' xs={12}>
            <ListDataItem text={physician.phone} icon={<PhoneIphoneIcon />} />
          </Grid>
        )}
        {physician.showEmail ? (
          <CardActions>
            <a href={`mailto:${physician.email}`}>
              <Button variant='contained' size='medium' onClick={handleContactClick}>
                Contact
              </Button>
            </a>
          </CardActions>
        ) : (
          <div style={{ height: '20px' }}></div>
        )}
      </Card>
    </Paper>
  )
}

export default PhysicianCard
