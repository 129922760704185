import React from 'react'
import { Box, Typography, Grid, Container, useTheme, Button } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import { transferData } from 'data/residencyTransfers/data'
import InfoSection from './InfoSection'
import ResidencyTransferCard from './ResidencyTransferCard'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const ResidencyTransferPage = () => {
  const theme = useTheme()
  const signedIn = useSelector(state => state.identity.signedIn)

  const renderAuthContent = () => (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
        Residency Transfer Opportunities
      </Typography>
      
      <InfoSection />

      <Typography 
        variant="body2" 
        sx={{ 
          mb: 3, 
          color: theme.palette.text.secondary,
          display: 'flex',
          alignItems: 'center',
          gap: 0.5 
        }}
      >
        <EmailIcon fontSize="small" />
        Click the contact button to reach out to residents directly about transfer opportunities
      </Typography>

      <Grid container spacing={3}>
        {transferData.map((transfer) => (
          <Grid item xs={12} sm={6} lg={4} key={transfer.id}>
            <ResidencyTransferCard transfer={transfer} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )

  const renderUnauthContent = () => (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
        Residency Transfer Opportunities
      </Typography>
      
      <InfoSection />

      <Box
        sx={{
          backgroundColor: 'white',
          padding: '2rem',
          borderRadius: '8px',
          border: `1px solid ${theme.palette.grey[300]}`,
          boxShadow: theme.shadows[3],
          textAlign: 'center',
          mt: 4
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, color: theme.palette.primary.main }}>
          Sign In Required
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Please sign in or create an account to view residency transfer opportunities
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button
            variant="contained"
            component={Link}
            to="/login"
            sx={{
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              }
            }}
          >
            Sign In
          </Button>
          <Button
            variant="outlined"
            component={Link}
            to="/register/physicians"
            sx={{
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              '&:hover': {
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.light,
              }
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Box>
  )

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        {signedIn ? renderAuthContent() : renderUnauthContent()}
      </Box>
    </Container>
  )
}

export default ResidencyTransferPage 