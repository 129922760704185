import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Grid, useTheme, Collapse, Link, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SaveIcon from '@mui/icons-material/Save'
import {
  generateFilters,
  updateSelectedFilters,
  getSelectedFiltersFromQueryString,
} from './helpers'
import { filterDefinitions, UserType, filterGroups } from './filterDefinitions'
import { useIdentity } from 'pages/Identity/Login'
import { useStyles } from './styles'
import { SavedFiltersButton } from './SavedFiltersButton'
import AlertsButton from './AlertsButton'
import { FilterHeader } from './FilterHeader'
import { MobileFilterDropdown } from './FilterDropdown/MobileFilterDropdown'
import { DesktopFilterDropdown } from './FilterDropdown/DesktopFilterDropdown'
import DesktopCompositeFilterDropdown from './FilterDropdown/DesktopCompositeFilterDropdown'
import { fetchUserFilterAlerts, fetchFilterTypes } from '../../redux/filters'
import PhysicianSignUpDialog from '../../components/Dialog/PhysicianSignUpDialog'
import SaveFilterDialog from '../Dialog/SaveFilterDialog'
import MobileCompositeFilterDropdown from './FilterDropdown/MobileCompositeFilterDropdown'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const AuthenticatedFilters = ({ filterType, filterValues, listType, applyFilters }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const styles = useStyles({ mobile })
  const { userFilterAlerts = [], filterTypes } = useSelector(state => state.filters)
  const { signedIn } = useSelector(state => state.identity)
  let ref = useRef(null)
  const { employerId, isEmployerAdmin, userId, employerUserId, candidateId } = useIdentity()
  const userType = employerId || isEmployerAdmin ? UserType.EMPLOYER : UserType.CANDIDATE
  const [searchParams, setSearchParams] = useSearchParams()
  const [areFiltersOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [showAllFilters, setShowAllFilters] = useState(false)
  const filters = generateFilters(filterType, userType, filterValues)
  const dispatch = useDispatch()
  const userFilterAlertParam = searchParams.get('userFilterAlertId')
  const [openSaveDialog, setOpenSaveDialog] = useState(false)
  const [openMoreDialog, setOpenMoreDialog] = useState(false)
  const [openSaveFilterDialog, setOpenSaveFilterDialog] = useState(false)

  useEffect(() => {
    dispatch(fetchFilterTypes())
  }, [])

  useEffect(() => {
    const filtersFromQuery = getSelectedFiltersFromQueryString(searchParams, filterValues)
    setSelectedFilters(prevFilters =>
      JSON.stringify(prevFilters) !== JSON.stringify(filtersFromQuery)
        ? filtersFromQuery
        : prevFilters,
    )
  }, [filterValues, searchParams])

  useEffect(() => {
    applyFilters({ searchParams, employerId, employerUserId, candidateId, isEmployerAdmin })
    Object.keys(selectedFilters).forEach(key => {
      const filterDef = filterDefinitions.find(filter => filter.name === key)
      if (filterDef && filterDef.showMoreFilters) {
        setShowAllFilters(true)
        return true
      }
    })
  }, [selectedFilters])

  useEffect(() => {
    dispatch(fetchUserFilterAlerts(userId))
  }, [userId])

  const userFilterAlertTypeName = listType === 'job' ? 'job' : 'candidate'
  const availableAlerts =
    userFilterAlerts.filter(
      alert =>
        alert.filterType.toLowerCase() === userFilterAlertTypeName && Number(alert.recordCount) > 0,
    ) || []

  const handleSaveDialogClose = () => {
    setOpenSaveDialog(false)
  }

  const handleSaveDialogOpen = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'save_filter_lead_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
      }),
    )
    setOpenSaveDialog(true)
  }

  const handleMoreDialogClose = () => setOpenMoreDialog(false)
  const handleMoreDialogOpen = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'more_filters_lead_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
      }),
    )
    setOpenMoreDialog(true)
  }

  const handleSaveFilterDialogClose = () => setOpenSaveFilterDialog(false)
  const handleSaveFilterDialogOpen = () => setOpenSaveFilterDialog(true)

  const handleFilterChange = selectedFilter => {
    const { filterDefinition, selectedValues } = selectedFilter

    const newSearchParams = filterDefinition.clearOthers
      ? new URLSearchParams()
      : new URLSearchParams(searchParams)

    if (selectedValues.length > 0) {
      const valueString = filterDefinition.isYesNo
        ? selectedValues[0].value === '1'
        : selectedValues.map(x => x.value).join('|')

      newSearchParams.set(filterDefinition.queryStringKey, valueString)
    } else {
      newSearchParams.delete(filterDefinition.queryStringKey)
    }

    setSearchParams(newSearchParams)

    setSelectedFilters(
      selectedValues.length > 0 ? updateSelectedFilters(selectedFilters, selectedFilter) : {},
    )
  }

  const handleClearAll = () => {
    const newSearchParams = new URLSearchParams(searchParams)
    filterDefinitions.forEach(filterDefinition => {
      if (filterDefinition.clearAll === true) {
        newSearchParams.delete(filterDefinition.queryStringKey)
      }
    })

    setSearchParams(newSearchParams)
    setSelectedFilters(currentFilters => {
      const updatedFilters = { ...currentFilters }
      Object.keys(updatedFilters).forEach(key => {
        if (filterDefinitions.find(filter => filter.name === key)) {
          delete updatedFilters[key]
        }
      })
      return updatedFilters
    })
  }

  const toggleShowAllFilters = () => {
    setShowAllFilters(!showAllFilters)
  }

  const handleSaveLinkClick = event => {
    if (!signedIn) {
      event.preventDefault()
      handleSaveDialogOpen()
    } else {
      handleSaveFilterDialogOpen()
    }
  }

  const handleMoreLinkClick = event => {
    if (!signedIn) {
      event.preventDefault()
      handleMoreDialogOpen()
    } else {
      toggleShowAllFilters()
    }
  }

  const handleGroupFilterChange = ({ selectedValues, filterDefinition }) => {
    // Copy the current searchParams
    const newSearchParams = new URLSearchParams(searchParams)
    if (selectedValues.length > 0) {
      const valueString = filterDefinition.isYesNo
        ? selectedValues[0].value.value === '1'
        : selectedValues.map(x => x.value).join('|')
      if (valueString) {
        newSearchParams.set(filterDefinition.queryStringKey, valueString)
      } else {
        newSearchParams.delete(filterDefinition.queryStringKey)
      }
    } else {
      newSearchParams.delete(filterDefinition.queryStringKey)
    }

    setSearchParams(newSearchParams)
    setSelectedFilters(prevFilters => ({
      ...prevFilters,
      [filterDefinition.name]: {
        filterDefinition,
        selectedValues,
      },
    }))
  }

  const renderFilters = filtersToRender => {
    const validFilters = filtersToRender.filter(
      filter => filter.filterValues && filter.filterValues.length > 0,
    )

    const { groupedFilters, ungroupedFilters } = validFilters.reduce(
      (acc, filter) => {
        if (filter.filterDefinition?.filterGroup) {
          const displayName = filterGroups.find(
            filterGroup => filterGroup.name === filter.filterDefinition.filterGroup,
          ).displayName
          const existingGroup = acc.groupedFilters.find(
            group => group.filterGroup === filter.filterDefinition?.filterGroup,
          )

          if (existingGroup) {
            existingGroup.filters.push(filter)
          } else {
            acc.groupedFilters.push({
              filterGroup: filter.filterDefinition.filterGroup,
              displayName: displayName,
              filters: [filter],
            })
          }
        } else {
          acc.ungroupedFilters.push(filter)
        }
        return acc
      },
      { groupedFilters: [], ungroupedFilters: [] },
    )

    const sortedFilters = [
      ...ungroupedFilters.filter(filter => !filter.filterDefinition.isYesNo),
      ...ungroupedFilters.filter(filter => filter.filterDefinition.isYesNo),
    ]

    return (
      <>
        <Grid container spacing={2}>
          {/* Render ungrouped filters */}
          {sortedFilters.map((filter, index) => {
            const selectedFilter = selectedFilters[filter.filterDefinition.name]
            const FilterComponent = mobile ? MobileFilterDropdown : DesktopFilterDropdown

            return (
              <Grid
                item
                key={`${filter.filterDefinition.name}-${index}`}
                xs={12}
                sm={12}
                md={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  paddingTop: '8px',
                }}
              >
                <FilterComponent
                  filterDefinition={filter.filterDefinition}
                  options={filter.filterValues}
                  checked={selectedFilter ? selectedFilter.selectedValues : null}
                  onChange={handleFilterChange}
                  styles={styles}
                  mobile={mobile}
                  filtersOpen={areFiltersOpen}
                />
              </Grid>
            )
          })}

          {/* Render grouped filters */}
          <Grid item xs={12} sm={12} md={2}>
            {groupedFilters.map(groupedFilter => {
              const CompositeFilterComponent = mobile
                ? MobileCompositeFilterDropdown
                : DesktopCompositeFilterDropdown
              return (
                <CompositeFilterComponent
                  groupedFilter={groupedFilter}
                  onChange={handleGroupFilterChange}
                  selectedFilters={selectedFilters}
                  styles={styles}
                />
              )
            })}
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid
        container
        ref={ref}
        justifyContent='center'
        sx={{
          overflow: 'hidden',
          padding: 0,
        }}
      >
        <Grid
          container
          className={`filterWrapper ${styles.filterHeaderWrapper}`}
          sx={{ overflow: 'hidden' }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ maxWidth: '70em', overflow: 'hidden' }}
          >
            <FilterHeader
              listType={listType}
              filterValues={filterValues}
              handleFilterChange={handleFilterChange}
              toggleShowAllFilters={toggleShowAllFilters}
              selectedFilters={selectedFilters}
              filterTypes={filterTypes}
              showAllFilters={showAllFilters}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className={`filterWrapper ${styles.filterWrapper}`}
          sx={{
            padding: mobile ? '16px' : '10px 0px',
          }}
        >
          <Grid
            container
            item
            alignItems='center'
            justifyContent='space-between'
            spacing={2}
            mb={1}
            sx={{ maxWidth: '70em', overflow: 'hidden', padding: '0px' }}
          >
            {/* Left-aligned Buttons */}
            <Grid item>
              <SavedFiltersButton
                listType={listType}
                variant='contained'
                size='medium'
                filterTypes={filterTypes}
              />
            </Grid>
            {availableAlerts.length > 0 && userFilterAlertParam !== null && (
              <Grid item xs={6} sm={6} md={6}>
                <AlertsButton availableAlerts={availableAlerts} />
              </Grid>
            )}

            {/* Right-aligned Buttons */}
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}
              >
                <Link
                  href='#'
                  onClick={handleMoreLinkClick}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: '1rem',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {showAllFilters ? 'Hide Filters' : 'More Filters'}
                  <ExpandMoreIcon
                    sx={{
                      marginLeft: '5px',
                      fontSize: '16px',
                      color: 'white',
                      transform: showAllFilters ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease',
                    }}
                  />
                </Link>
                <Link
                  href='#'
                  onClick={handleClearAll}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: '1rem',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Clear Filters
                </Link>
                <Link
                  href='#'
                  onClick={handleSaveLinkClick}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: '1rem',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Save Filters
                  <SaveIcon
                    sx={{
                      marginLeft: '5px',
                      fontSize: '16px',
                      color: 'white',
                    }}
                  />
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            spacing={0}
            sx={{ maxWidth: '70em', overflow: 'hidden' }}
          >
            <Collapse in={showAllFilters} sx={{ width: '100%' }}>
              <Grid item container direction='row' alignItems='center' spacing={0}>
                {renderFilters(filters)}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
      <PhysicianSignUpDialog
        openDialog={openSaveDialog}
        handleDialogClose={handleSaveDialogClose}
        feature='save'
      />
      <PhysicianSignUpDialog
        openDialog={openMoreDialog}
        handleDialogClose={handleMoreDialogClose}
        feature='more'
      />
      <SaveFilterDialog
        openDialog={openSaveFilterDialog}
        handleDialogClose={handleSaveFilterDialogClose}
        selectedFilters={selectedFilters}
        filterTypes={filterTypes}
        listType={listType}
      />
    </>
  )
}
